import React from "react";
import theme from "theme";
import { Theme, Text, Box, Strong, Hr, Section, Icon, Image, Button } from "@quarkly/widgets";
import { Helmet } from "react-helmet";
import { GlobalQuarklyPageStyles } from "global-page-styles";
import { RawHtml, Override } from "@quarkly/components";
import * as Components from "components";
import { MdDone } from "react-icons/md";
export default (() => {
	return <Theme theme={theme}>
		<GlobalQuarklyPageStyles pageUrl={"services"} />
		<Helmet>
			<title>
				ClearPath
			</title>
			<meta name={"description"} content={"Your Cleaning Expert"} />
			<meta property={"og:title"} content={"ClearPath"} />
			<meta property={"og:description"} content={"Your Cleaning Expert"} />
			<meta property={"og:image"} content={"https://uploads.quarkly.io/65e71749009abe001f042f63/images/1-3.jpg?v=2024-03-06T09:51:00.290Z"} />
			<link rel={"shortcut icon"} href={"https://uploads.quarkly.io/65e71749009abe001f042f63/images/1-3.jpg?v=2024-03-06T09:51:00.290Z"} type={"image/x-icon"} />
			<link rel={"apple-touch-icon"} href={"https://uploads.quarkly.io/65e71749009abe001f042f63/images/1-3.jpg?v=2024-03-06T09:51:00.290Z"} />
			<link rel={"apple-touch-icon"} sizes={"76x76"} href={"https://uploads.quarkly.io/65e71749009abe001f042f63/images/1-3.jpg?v=2024-03-06T09:51:00.290Z"} />
			<link rel={"apple-touch-icon"} sizes={"152x152"} href={"https://uploads.quarkly.io/65e71749009abe001f042f63/images/1-3.jpg?v=2024-03-06T09:51:00.290Z"} />
			<link rel={"apple-touch-startup-image"} href={"https://uploads.quarkly.io/65e71749009abe001f042f63/images/1-3.jpg?v=2024-03-06T09:51:00.290Z"} />
			<meta name={"msapplication-TileImage"} content={"https://uploads.quarkly.io/65e71749009abe001f042f63/images/1-3.jpg?v=2024-03-06T09:51:00.290Z"} />
		</Helmet>
		<Components.Header />
		<Section background="--color-dark" padding="80px 0 80px 0" quarkly-title="Schedule-7">
			<Override
				slot="SectionContent"
				flex-direction="row"
				flex-wrap="wrap"
				display="grid"
				grid-gap="88px"
				lg-grid-gap="64px"
				sm-grid-gap="32px"
			/>
			<Box
				lg-width="100%"
				lg-align-items="center"
				lg-display="flex"
				lg-justify-content="center"
				lg-flex-direction="column"
				sm-align-items="flex-start"
			>
				<Text margin="0px 0px 8px 0px" font="--headline1" color="--light" sm-font="normal 900 42px/1.2 -apple-system, system-ui, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif">
					Range of Services
				</Text>
				<Text margin="0px 0px 0px 0px" font="--lead" color="--light" text-align="left">
					At ClearPath Cleaning Services, we offer a comprehensive range of cleaning services designed to meet all your needs, ensuring your spaces are not only clean but truly transformed. From deep cleaning to regular maintenance, our team is equipped to provide clarity and cleanliness in every corner of your home or office.
				</Text>
			</Box>
			<Box
				display="grid"
				lg-width="100%"
				sm-flex-wrap="wrap"
				grid-template-columns="1fr 1fr"
				grid-gap="150px"
				sm-grid-template-columns="1fr"
				sm-grid-gap="40px"
			>
				<Box>
					<Text margin="0px 0px 16px 0px" font="--headline2" color="--light" lg-font="normal 900 28px/1.2 -apple-system, system-ui, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif">
						Cleaning Services Overview
					</Text>
				</Box>
				<Box display="grid" grid-gap="16px">
					<Text margin="0px 0px 0px 0px" font="--lead" color="--lightD2" lg-font="normal 500 20px/1.2 -apple-system, system-ui, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif">
						<Strong>
							"Home Sweet Home" Cleaning:
						</Strong>
						designed to bring out the best in your living spaces, including dusting, vacuuming, mopping, and more to make every room shine.
						<br />
						<br />
						<Strong
							overflow-wrap="normal"
							word-break="normal"
							white-space="normal"
							text-indent="0"
							text-overflow="clip"
							hyphens="manual"
							user-select="auto"
							pointer-events="auto"
						>
							Office and Commercial Spaces:
						</Strong>
						specialized cleaning procedures aimed at maintaining a professional and welcoming atmosphere for businesses of any size.
						<br />
						<br />
						<Strong
							overflow-wrap="normal"
							word-break="normal"
							white-space="normal"
							text-indent="0"
							text-overflow="clip"
							hyphens="manual"
							user-select="auto"
							pointer-events="auto"
						>
							Deep Cleaning:
						</Strong>
						an intensive cleaning option covering every inch of your space, perfect for seasonal cleaning or preparing for special occasions.
					</Text>
				</Box>
			</Box>
			<Hr
				min-width="100%"
				margin="0px 0px 0px 0px"
				border-color="--color-darkL2"
				padding="0px 0px 0px 0px"
				height="2px"
				border-width="2px 0px 0px 0px"
			/>
			<Box
				display="grid"
				lg-width="100%"
				sm-flex-wrap="wrap"
				grid-template-columns="1fr 1fr"
				grid-gap="150px"
				sm-grid-template-columns="1fr"
				sm-grid-gap="40px"
			>
				<Box>
					<Text margin="0px 0px 16px 0px" font="--headline2" color="--light" lg-font="normal 900 28px/1.2 -apple-system, system-ui, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif">
						Specialized Services
					</Text>
				</Box>
				<Text margin="0px 0px 0px 0px" font="--lead" color="--lightD2" lg-font="normal 500 20px/1.2 -apple-system, system-ui, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif">
					<Strong>
						Eco-Friendly Cleaning:
					</Strong>
					using environmentally friendly cleaning products and methods to protect your health and the environment without compromising on effectiveness.
					<br />
					<br />
					<Strong>
						Move-In/Move-Out Cleaning:
					</Strong>
					make transitions smoother with thorough cleaning that prepares your space for the next chapter, whether you are moving in or out.
				</Text>
			</Box>
			<Hr
				min-width="100%"
				margin="0px 0px 0px 0px"
				border-color="--color-darkL2"
				padding="0px 0px 0px 0px"
				height="2px"
				border-width="2px 0px 0px 0px"
			/>
		</Section>
		<Section padding="80px 0 80px 0" sm-padding="60px 0px 60px 0px" quarkly-title="Advantages/Features-24">
			<Override slot="SectionContent" flex-direction="row" flex-wrap="wrap" sm-min-width="280px" />
			<Box
				display="flex"
				width="50%"
				flex-direction="column"
				justify-content="flex-start"
				align-items="flex-start"
				lg-width="100%"
				lg-align-items="flex-start"
				lg-margin="0px 0px 60px 0px"
				sm-margin="0px 0px 40px 0px"
				sm-padding="0px 0px 0px 0px"
				lg-flex-direction="row"
				lg-flex-wrap="wrap"
			>
				<Text
					margin="0px 0px 48px 0px"
					color="--dark"
					font="--headline2"
					lg-text-align="center"
					sm-font="--headline3"
					lg-width="100%"
				>
					Why Choose ClearPath
				</Text>
				<Box
					display="flex"
					align-items="flex-start"
					margin="0px 0px 32px 0px"
					lg-width="100%"
					md-width="100%"
					md-margin="0px 0px 32px 0px"
					md-padding="0px 0px 0px 0px"
				>
					<Icon
						category="md"
						icon={MdDone}
						size="36px"
						color="--light"
						border-radius="50%"
						padding="8px 8px 8px 8px"
						background="--color-primary"
					/>
					<Box margin="0px 0px 0px 22px" sm-margin="0px 0px 0px 12px">
						<Text
							margin="0px 0px 6px 0px"
							color="--darkL2"
							font="--headline3"
							lg-text-align="left"
							sm-font="--lead"
						>
							Flexibility and Reliability
						</Text>
						<Text margin="0px 0px 0px 0px" color="--greyD3" font="--base" lg-text-align="left">
							We adapt to your schedule and cleaning preferences, providing reliable service that fits your lifestyle.
						</Text>
					</Box>
				</Box>
				<Box
					display="flex"
					align-items="flex-start"
					margin="0px 0px 32px 0px"
					lg-width="100%"
					md-width="100%"
					md-margin="0px 0px 32px 0px"
					md-padding="0px 0px 0px 0px"
				>
					<Icon
						category="md"
						icon={MdDone}
						size="36px"
						background="--color-primary"
						color="--light"
						border-radius="50%"
						padding="8px 8px 8\n\npx 8px"
					/>
					<Box margin="0px 0px 0px 22px" sm-margin="0px 0px 0px 12px">
						<Text
							margin="0px 0px 6px 0px"
							color="--darkL2"
							font="--headline3"
							lg-text-align="left"
							sm-font="--lead"
						>
							Professional Team
						</Text>
						<Text margin="0px 0px 0px 0px" color="--greyD3" font="--base" lg-text-align="left">
							Our cleaners are trained, respectful, and committed to delivering high-quality service with a personal touch.
						</Text>
					</Box>
				</Box>
				<Box
					display="flex"
					align-items="flex-start"
					lg-width="100%"
					md-width="100%"
					md-padding="0px 0px 0px 0px"
				>
					<Icon
						category="md"
						icon={MdDone}
						size="36px"
						background="--color-primary"
						color="--light"
						border-radius="50%"
						padding="8px 8px 8px 8px"
					/>
					<Box margin="0px 0px 0px 22px" sm-margin="0px 0px 0px 12px">
						<Text
							margin="0px 0px 6px 0px"
							color="--darkL2"
							font="--headline3"
							lg-text-align="left"
							sm-font="--lead"
						>
							Satisfaction Guaranteed
						</Text>
						<Text margin="0px 0px 0px 0px" color="--greyD3" font="--base" lg-text-align="left">
							We strive for perfection and your complete satisfaction, ready to address any issues to meet your expectations.
						</Text>
					</Box>
				</Box>
			</Box>
			<Box
				display="flex"
				width="50%"
				justify-content="center"
				lg-width="100%"
				padding="0px 0px 0px 16px"
				align-items="center"
				lg-justify-content="center"
				lg-padding="0px 0px 0px 0px"
			>
				<Image
					src="https://uploads.quarkly.io/65e71749009abe001f042f63/images/3-3.jpg?v=2024-03-06T09:51:00.294Z"
					max-width="400px"
					border-radius="8px"
					height="100%"
					object-fit="cover"
					lg-width="100%"
					lg-max-width="none"
					srcSet="https://smartuploads.quarkly.io/65e71749009abe001f042f63/images/3-3.jpg?v=2024-03-06T09%3A51%3A00.294Z&quality=85&w=500 500w,https://smartuploads.quarkly.io/65e71749009abe001f042f63/images/3-3.jpg?v=2024-03-06T09%3A51%3A00.294Z&quality=85&w=800 800w,https://smartuploads.quarkly.io/65e71749009abe001f042f63/images/3-3.jpg?v=2024-03-06T09%3A51%3A00.294Z&quality=85&w=1080 1080w,https://smartuploads.quarkly.io/65e71749009abe001f042f63/images/3-3.jpg?v=2024-03-06T09%3A51%3A00.294Z&quality=85&w=1600 1600w,https://smartuploads.quarkly.io/65e71749009abe001f042f63/images/3-3.jpg?v=2024-03-06T09%3A51%3A00.294Z&quality=85&w=2000 2000w,https://smartuploads.quarkly.io/65e71749009abe001f042f63/images/3-3.jpg?v=2024-03-06T09%3A51%3A00.294Z&quality=85&w=2600 2600w,https://smartuploads.quarkly.io/65e71749009abe001f042f63/images/3-3.jpg?v=2024-03-06T09%3A51%3A00.294Z&quality=85&w=3200 3200w"
					sizes="(max-width: 576px) 100vw,(max-width: 768px) 100vw,(max-width: 992px) 100vw,100vw"
				/>
			</Box>
		</Section>
		<Section padding="80px 0" sm-padding="40px 0">
			<Override slot="SectionContent" align-items="center" />
			<Text
				as="h2"
				font="--headline3"
				md-font="--headline2"
				margin="20px 0 0 0"
				text-align="center"
			>
				Start your eco-friendly journey with us
			</Text>
			<Text as="p" font="--lead" margin="20px 0 20px 0" text-align="center">
				Let ClearPath Cleaning Services be your partner in creating a cleaner and more peaceful environment. Whether you want to refresh your home or maintain a professional business space, we are here to develop a cleaning plan that perfectly suits your needs.
			</Text>
			<Button
				font="--lead"
				margin="20px"
				type="link"
				text-decoration-line="initial"
				href="/contacts"
			>
				Contact Us
			</Button>
		</Section>
		<Components.Footer />
		<RawHtml>
			<style place={"endOfHead"} rawKey={"65dde662009abe001f03e3dd"}>
				{":root {\n  box-sizing: border-box;\n}\n\n* {\n  box-sizing: inherit;\n}"}
			</style>
		</RawHtml>
	</Theme>;
});